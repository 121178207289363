<template>
  <div class="card">
    <div class="card-header">
      <a @click="toggleExpansion">
        <h4 class="card-title mt-2 mb-0">
          Reservation History
          <i class="fa float-right" :class="icon"></i>
        </h4>
      </a>
    </div>
    <div class="card-body" v-if="this.showHistory">
      <div v-if="!loading">
        <div class="row">
          <b-table
            ref="table"
            striped
            :fields="fields"
            :items="history"
            stacked="md"
            no-local-sorting
            small
            bordered
            sort-icon-left
          >
            <template v-slot:cell(fees)="data">
              <span v-html="data.value"></span>
            </template>
            <template v-slot:cell(username)="data">
              <div>{{ data.value }}</div>
              <div v-if="data.item.salesChannel">
                Sales Channel: {{ data.item.salesChannel }}
              </div>
            </template>
            <template v-slot:cell(notes)="data">
              <span
                class="notes"
                v-html="data.value"
                v-if="!data.item.loading"
              ></span>
              <span
                class="spinner-border spinner-border-sm"
                role="status"
                aria-hidden="true"
                v-else
              ></span>
            </template>
          </b-table>
        </div>
      </div>
      <div v-else class="my-3 text-center">
        <span
          class="spinner-border spinner-border-sm"
          role="status"
          aria-hidden="true"
        ></span>
      </div>
    </div>
  </div>
</template>

<script>
import AdminSpotReservationService from "@/services/admin/AdminSpotReservationService.js";
import moment from "moment";

export default {
  name: "AdminReservationHistory",
  props: {
    reservationId: Number,
    reloadHistory: Boolean
  },
  data() {
    return {
      history: null,
      showHistory: false,
      fields: [
        { key: "activityDate", formatter: "formatDate" },
        { key: "username" },
        { key: "actionPerformed" },
        { key: "notes" },
        { key: "fees", formatter: "formatFees" }
      ],
      loading: false
    };
  },
  computed: {
    icon() {
      if (this.showHistory) {
        return "fa-angle-down";
      }
      return "fa-angle-up";
    },
    tenantId() {
      return this.$store.getters["tenant/tenantId"];
    }
  },

  methods: {
    toggleExpansion() {
      this.showHistory = !this.showHistory;
      if (this.showHistory && this.history == null) {
        this.loadHistory();
      }
    },
    loadHistory() {
      this.loading = true;
      const spotReservationService = new AdminSpotReservationService(
        this.tenantId
      );
      if (this.reservationId) {
        spotReservationService
          .getHistory(this.reservationId)
          .then(response => {
            if (response.data) {
              this.history = response.data;
              this.history.forEach(async item => {
                if (
                  !(
                    item.auditBusinessAction == "Checkin" ||
                    item.auditBusinessAction == "Checkout" ||
                    item.auditBusinessAction == "UndoCheckin" ||
                    item.auditBusinessAction == "UndoCheckout" ||
                    item.auditBusinessAction == "Chargeback" ||
                    item.auditBusinessAction == "UndoChargeback"
                  ) &&
                  item.confirmationNumber
                ) {
                  item.notes +=
                    "<br/>" + "Confirmation Number: " + item.confirmationNumber;
                }
                if (
                  item.auditBusinessAction == "Checkin" ||
                  item.auditBusinessAction == "Checkout" ||
                  item.auditBusinessAction == "UndoCheckin" ||
                  item.auditBusinessAction == "UndoCheckout"
                ) {
                  item.fees = "";
                }
                this.$set(item, "loading", true);
                await this.loadFieldChanges(item);
                this.$set(item, "loading", false);
              });
            } else {
              this.history = [];
            }
          })
          .finally(() => {
            this.loading = false;
          });
      }
    },
    async loadFieldChanges(historyItem) {
      const spotReservationService = new AdminSpotReservationService(
        this.tenantId
      );
      const response = await spotReservationService.getChangeHistory(
        historyItem.spotReservationId,
        historyItem.auditTransactionId
      );
      if (response.data) {
        var item = this.history.find(
          n => n.auditTransactionId == historyItem.auditTransactionId
        );
        response.data.forEach(
          field =>
            (item.notes +=
              "<br/>" +
              field.fieldName +
              " changed from '" +
              field.oldValue +
              "' to '" +
              field.newValue +
              "' ")
        );
      } else {
        this.history = [];
      }
    },
    formatDate(date) {
      return moment(date, "YYYY-MM-DDThh:mm:ss.SSSZ").format(
        "MM/DD/YYYY hh:mm A"
      );
    },
    formatFees(fees) {
      const feeArray = fees.split(",");
      const formattedFee = feeArray.join("<br/>");
      return formattedFee.replace(/¤/g, "$");
    }
  },
  watch: {
    reloadHistory() {
      if (this.reloadHistory === true) {
        this.loadHistory();
        this.$emit("historyLoaded");
      }
    }
  }
};
</script>

<style scoped>
.footer {
  font-size: 0.8em;
}
.success {
  vertical-align: middle;
  color: green;
}
.notes {
  white-space: pre-line;
}
</style>
