<template>
  <div class="my-3 mx-4">
    <div class="row">
      <div class="col-12">
        <div class="heading">Reservation Details</div>
        <div class="my-3">
          <button
            class="btn btn-success mr-2"
            type="button"
            v-if="checkInVisible"
            @click="checkIn"
          >
            <i class="fas fa-calendar-check"></i> CHECK IN
          </button>
          <button
            class="btn btn-success mr-2"
            type="button"
            v-if="checkOutVisible"
            @click="checkOut"
          >
            <i class="fas fa-calendar-times"></i> CHECK OUT
          </button>
          <button
            class="btn btn-danger mr-2"
            type="button"
            v-if="undoCheckInVisible"
            @click="undo"
          >
            <i class="fas fa-undo"></i> UNDO CHECK IN
          </button>
          <button
            class="btn btn-danger mr-2"
            type="button"
            v-if="undoCheckOutVisible"
            @click="undo"
          >
            <i class="fas fa-undo"></i> UNDO CHECK OUT
          </button>
          <button
            class="btn btn-primary mr-2"
            type="button"
            v-if="canModify"
            @click="edit"
          >
            <i class="fas fa-edit"></i> RESERVATION MODIFY
          </button>
          <button
            class="btn btn-primary mr-2"
            type="button"
            v-if="canCancel"
            @click="cancel"
          >
            <i class="fas fa-times-circle"></i> RESERVATION CANCEL
          </button>
          <button
            class="btn btn-primary mr-2"
            type="button"
            v-if="
              reservation.status == 'Reserved' &&
                !reservation.paymentChargebackCompleted &&
                this.checkPermission('ReservationManagementChargeback')
            "
            @click="chargebackModal"
          >
            <i class="fas fa-dollar-sign"></i> PAYMENT CHARGEBACK
          </button>
          <button
            class="btn btn-primary mr-2"
            type="button"
            v-if="
              reservation.status == 'Reserved' &&
                !!reservation.paymentChargebackCompleted &&
                this.checkPermission('ReservationManagementChargeback')
            "
            @click="undoChargeback"
          >
            <i class="fas fa-dollar-sign"></i> UNDO PAYMENT CHARGEBACK
          </button>
          <button
            class="btn btn-primary mr-2"
            type="button"
            v-if="reservation.status == 'Reserved'"
            @click="resendModal"
          >
            <i class="fas fa-envelope"></i> RESEND CONFIRMATION EMAIL
          </button>
        </div>
        <AdminReservationSummary :reservation="reservation" />
        <AdminReservationContact
          header="Customer"
          :contact="reservation.customerContact"
        />
        <AdminReservationComments :reservationId="reservation.id" />
        <AdminReservationHistory
          :reservationId="reservation.id"
          :reloadHistory="reloadHistory"
          @historyLoaded="reloadHistory = false"
        />
        <button class="btn btn-primary" type="button" @click="back">
          BACK
        </button>
      </div>
    </div>
    <b-modal id="undoModal" :title="undoModalTitle" @ok="confirmUndo">
      <div class="font-weight-bold">
        Confirm to undo the check-in/check-out of the reservation. The status
        will revert to its previous state. The reservation detail screen will
        refresh when completed.
      </div>
      <br />
      <div><b>Reservation Number: </b>{{ reservation.reservationNumber }}</div>
      <div><b>Spot Name: </b>{{ reservation.spotName }}</div>
      <div v-if="reservation.customerContact">
        <b>Customer Name: </b>{{ reservation.customerContact.firstName }}
        {{ reservation.customerContact.lastName }}
      </div>
      <div><b>Status: </b> {{ reservation.subStatus }}</div>
      <br />
      <div></div>
      <template #modal-footer="{ ok, cancel }">
        <button type="button" @click="ok()" class="btn btn-primary">
          Undo
        </button>
        <button type="button" @click="cancel()" class="btn">Cancel</button>
      </template>
    </b-modal>
    <b-modal id="resendModal" title="Resend Confirmation Email" size="sm">
      <ValidationObserver ref="resendEmailForm">
        <form>
          <FormErrorAlert
            v-if="errors.length > 0"
            :errors="errors"
            :formRef="this.$refs.resendEmailForm"
          />
          <div class="text-center my-2" v-if="!this.user.email">
            <span
              class="spinner-border spinner-border-sm mx-auto"
              role="status"
              aria-hidden="true"
            ></span>
          </div>
          <div class=" col-sm-12" v-else>
            <div>
              <input
                type="checkbox"
                class="mr-2"
                id="customer"
                v-model="resendEmailOptions.customer"
              />
              <label class="form-check-label" for="customer"
                >Customer email address</label
              >
            </div>
            <div>
              <input
                type="checkbox"
                class="mr-2"
                id="admin"
                v-model="resendEmailOptions.admin"
              />
              <label class="form-check-label" for="admin">{{
                this.user.email
              }}</label>
            </div>
            <div>
              <input
                type="checkbox"
                class="mr-2"
                id="customEmail"
                v-model="resendEmailOptions.custom"
              />
              <label class="form-check-label" for="customEmail"
                >Custom email address(es)</label
              >
            </div>
          </div>
          <div class="col-sm-12 mt-2" v-if="resendEmailOptions.custom">
            <TextInput
              rules="required|min:3|max:256"
              name="Email Address(es)"
              id="email"
              v-model="resendEmailAddress"
              type="email"
              :multiple="true"
            />
            <small class="form-text text-muted mb-2"
              >Separate emails by comma.</small
            >
          </div>
        </form>
      </ValidationObserver>
      <template #modal-footer="{  cancel }">
        <button
          type="button"
          @click="resendEmail"
          class="btn btn-primary"
          :disabled="
            !resendEmailOptions.customer &&
              !resendEmailOptions.admin &&
              !resendEmailOptions.custom
          "
        >
          Send
        </button>
        <button type="button" @click="cancel()" class="btn">Cancel</button>
      </template>
    </b-modal>
    <b-modal id="chargebackModal" title="Payment Chargeback" size="md">
      <TextInput
        rules="required"
        name="Comment"
        id="comment"
        v-model="chargebackRequest.comment"
        type="text"
        rows="3"
      />
      <template #modal-footer="{ cancel }">
        <button type="button" @click="chargeback" class="btn btn-primary">
          Chargeback
        </button>
        <button type="button" @click="cancel()" class="btn">Cancel</button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import AdminReservationContact from "@/components/admin/AdminReservationContact.vue";
import AdminReservationSummary from "@/components/admin/AdminReservationSummary.vue";
import AdminReservationComments from "@/components/admin/AdminReservationComments.vue";
import AdminReservationHistory from "@/components/admin/AdminReservationHistory.vue";
import AdminSpotReservationService from "@/services/admin/AdminSpotReservationService.js";
import AdminProfileService from "@/services/admin/AdminProfileService.js";
import checkPermissionMixin from "@/mixins/PermissionCheckMixin.js";
import moment from "moment";
import TextInput from "@/validation/TextInput.vue";
import { ValidationObserver } from "vee-validate";
import FormErrorAlert from "@/components/alert/FormErrorAlert.vue";
import AdminUserService from "@/services/admin/AdminUserService.js";

export default {
  name: "AdminReservationDetails",
  title: "Admin - Reservation Details",
  mixins: [checkPermissionMixin],
  components: {
    AdminReservationContact,
    AdminReservationSummary,
    AdminReservationComments,
    AdminReservationHistory,
    TextInput,
    ValidationObserver,
    FormErrorAlert
  },
  props: {
    reservationId: String
  },
  data() {
    return {
      reservation: {},
      reloadHistory: false,
      resendEmailOptions: {
        customer: true,
        admin: false,
        custom: false
      },
      user: {},
      resendEmailAddress: null,
      chargebackRequest: {
        comment: null
      },
      errors: []
    };
  },
  computed: {
    tenantId() {
      return this.$store.getters["tenant/tenantId"];
    },
    username() {
      return this.$store.getters["auth/username"];
    },
    canCancel() {
      return (
        this.reservation.status == "Reserved" &&
        this.checkPermission("ReservationManagementCancel")
      );
    },
    canModify() {
      return (
        this.reservation.status != "Canceled" &&
        this.checkPermission("ReservationManagementModify")
      );
    },
    isCashPaymentType() {
      return !this.reservation.customerModifiable;
    },
    checkInVisible() {
      return (
        !this.reservation.subStatus &&
        this.reservation.status === "Reserved" &&
        moment().isSameOrAfter(
          moment(this.reservation.arrivalDate, "MM/DD/YYYY"),
          "days"
        ) &&
        this.checkLocationPermission(
          "ReservationManagementView",
          this.reservation.locationId
        )
      );
    },
    checkOutVisible() {
      return (
        this.reservation.subStatus == "CheckedIn" &&
        this.checkLocationPermission(
          "ReservationManagementView",
          this.reservation.locationId
        )
      );
    },
    undoCheckInVisible() {
      return (
        this.reservation.subStatus == "CheckedIn" &&
        this.checkLocationPermission(
          "ReservationManagementModify",
          this.reservation.locationId
        )
      );
    },
    undoCheckOutVisible() {
      return (
        this.reservation.subStatus == "CheckedOut" &&
        this.checkLocationPermission(
          "ReservationManagementModify",
          this.reservation.locationId
        )
      );
    },
    undoModalTitle() {
      if (this.undoCheckInVisible) {
        return "Confirm Check-In Undo";
      } else {
        return "Confirm Check-Out Undo";
      }
    }
  },
  methods: {
    resendEmail() {
      try {
        this.$refs.resendEmailForm.validate().then(async success => {
          if (!success) {
            setTimeout(() => {
              const errors = Object.entries(this.$refs.resendEmailForm.errors)
                .map(([key, value]) => ({ key, value }))
                .filter(error => error["value"].length);
              this.errors = errors;
              this.$refs.resendEmailForm.refs[
                errors[0]["key"]
              ].$el.scrollIntoView({
                behavior: "smooth",
                block: "center"
              });
            }, 100);
          } else {
            this.errors = [];
            this.$bvModal.hide("resendModal");
            this.$store.commit("auth/setLoading", true);
            const profileService = new AdminProfileService(this.tenantId);
            let request = {
              customerId: this.reservation.customerId,
              sendToCustomer: this.resendEmailOptions.customer,
              reservationId: this.reservation.id,
              emailAddresses: this.resendEmailAddress
                ?.split(",")
                .map(x => x.trim()),
              adminUsername: this.resendEmailOptions.admin
                ? this.username
                : null
            };
            const response = await profileService.sendReservationConfirmationEmail(
              request
            );
            if (response.statusMessage === "Success") {
              this.$store.commit("auth/setLoading", false);
              this.resendEmailOptions = {
                customer: true,
                admin: false,
                custom: false
              };
              this.resendEmailAddress = null;
              this.$store.commit("alert/setErrorAlert", {
                type: "alert-info",
                message: "Reservation Confirmation email has been sent.",
                layer: "admin"
              });
            }
          }
        });
      } catch (e) {
        this.$store.commit("alert/setErrorAlert", {
          type: "alert-danger",
          message: "Something went wrong...",
          layer: "admin"
        });
      } finally {
        this.$store.commit("auth/setLoading", false);
      }
    },
    async chargeback() {
      try {
        this.$bvModal.hide("chargebackModal");
        this.$store.commit("auth/setLoading", true);
        this.chargebackRequest.spotReservationId = this.reservationId;
        const spotReservationService = new AdminSpotReservationService(
          this.tenantId
        );
        let response = await spotReservationService.chargebackReservation(
          this.chargebackRequest
        );
        if (response.statusCode == "Success") {
          this.$store.commit("alert/setErrorAlert", {
            type: "alert-success",
            message: "Chargeback Payment Completed.",
            layer: "admin"
          });
          this.reservation.paymentChargebackCompleted = true;
        } else {
          this.$store.commit("alert/setErrorAlert", {
            type: "alert-danger",
            message: "Something went wrong...",
            layer: "admin"
          });
        }
      } catch (e) {
        this.$store.commit("alert/setErrorAlert", {
          type: "alert-danger",
          message: "Something went wrong...",
          layer: "admin"
        });
      } finally {
        this.$store.commit("auth/setLoading", false);
      }
    },
    async undoChargeback() {
      try {
        this.$store.commit("auth/setLoading", true);
        const spotReservationService = new AdminSpotReservationService(
          this.tenantId
        );
        let response = await spotReservationService.undoChargebackReservation(
          this.reservationId
        );
        if (response.statusCode == "Success") {
          this.$store.commit("alert/setErrorAlert", {
            type: "alert-success",
            message: "Chargeback Payment undone.",
            layer: "admin"
          });
          this.reservation.paymentChargebackCompleted = false;
        } else {
          this.$store.commit("alert/setErrorAlert", {
            type: "alert-danger",
            message: "Something went wrong...",
            layer: "admin"
          });
        }
      } catch (e) {
        this.$store.commit("alert/setErrorAlert", {
          type: "alert-danger",
          message: "Something went wrong...",
          layer: "admin"
        });
      } finally {
        this.$store.commit("auth/setLoading", false);
      }
    },
    async getReservationDetail() {
      try {
        this.$store.commit("auth/setLoading", true);
        const spotReservationService = new AdminSpotReservationService(
          this.tenantId
        );
        const response = await spotReservationService.getReservationDetail(
          this.reservationId
        );
        this.reservation = response.data;
      } catch (e) {
        this.$store.commit("alert/setErrorAlert", {
          type: "alert-danger",
          message: "Something went wrong...",
          layer: "admin"
        });
      } finally {
        this.$store.commit("auth/setLoading", false);
      }
    },
    back() {
      this.reservation = {};
      this.$router.push(`/admin/reservation-search`).catch(() => {});
    },
    edit() {
      this.$store.dispatch("transaction/clear");
      this.$router
        .push(`/admin/reservation-edit-spot/${this.reservationId}`)
        .catch(() => {});
    },
    cancel() {
      this.$store.dispatch("transaction/clear");
      this.$router
        .push(`/admin/reservation-cancel/${this.reservationId}`)
        .catch(() => {});
    },
    checkIn() {
      this.$router
        .push(`/admin/reservation-check-in/${this.reservationId}`)
        .catch(() => {});
    },
    checkOut() {
      this.$router
        .push(`/admin/reservation-check-out/${this.reservationId}`)
        .catch(() => {});
    },
    undo() {
      this.$bvModal.show("undoModal");
    },
    async confirmUndo() {
      try {
        this.$store.commit("auth/setLoading", true);
        const spotReservationService = new AdminSpotReservationService(
          this.tenantId
        );
        let response;
        if (this.undoCheckInVisible) {
          response = await spotReservationService.undoCheckin(
            this.reservation.id
          );
        } else {
          response = await spotReservationService.undoCheckout(
            this.reservation.id
          );
        }
        if (response.statusCode == "Success") {
          this.$store.commit("alert/setErrorAlert", {
            type: "alert-success",
            message: "Reservation check-in status is updated.",
            layer: "admin"
          });
        } else {
          this.$store.commit("alert/setErrorAlert", {
            type: "alert-danger",
            message: "Something went wrong...",
            layer: "admin"
          });
        }
        this.getReservationDetail();
        this.reloadHistory = true;
      } catch (e) {
        this.$store.commit("alert/setErrorAlert", {
          type: "alert-danger",
          message: "Something went wrong...",
          layer: "admin"
        });
      } finally {
        this.$store.commit("auth/setLoading", false);
      }
    },
    async resendModal() {
      this.$bvModal.show("resendModal");
      let userService = new AdminUserService(this.tenantId);
      this.user = await userService.getUserByUsername(this.username);
    },
    async chargebackModal() {
      this.$bvModal.show("chargebackModal");
    }
  },
  created() {
    this.getReservationDetail();
  }
};
</script>

<style scoped>
.label {
  font-weight: bold;
  display: inline-block;
  margin-bottom: 0.5rem;
}
.heading {
  font-size: x-large;
}
</style>
